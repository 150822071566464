import React from "react";

const PrivacyPolicy = () => {

    return (
        <div className="bg-[#F8FEFF] min-h-screen flex justify-center px-4">
            <div className="w-full max-w-[800px] mt-10 bg-white rounded-lg shadow-md p-6">
                <h1 className="text-3xl font-semibold mb-4">Privacy Policy</h1>
                <p className="mb-4">Last updated: 2nd January 2025</p>
                <p className="mb-4">
                    This privacy policy ("Policy") describes how Waitmate ("Company," "we," "us," or "our") collects, protects, and uses the personally identifiable information ("Personal Information") you ("User," "you," or "your") may provide on the waitmate.app website and any of its products or services (collectively, "Website" or "Services").
                </p>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">1. Collection and Use of Personal Information</h2>
                    <p>
                        We receive and store any information you knowingly provide to us when you fill any online forms on the Website. This includes, but is not limited to, your name, email address, phone number, and booking details. Provision of this information is subject to your explicit consent, which you provide by opting in through a clear affirmative action as part of our registration process. You may withdraw your consent at any time by contacting us at support@waitmate.app.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">2. Collection of Non-Personal Information</h2>
                    <p>
                        When you visit the Website, our servers automatically record information that your browser sends. This data may include information such as your device's IP address, browser type and version, operating system type and version, language preferences, or the webpage you were visiting before you came to our Website, pages of our Website that you visit, the time spent on those pages, and other statistics.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">3. Use of Collected Information</h2>
                    <p>
                        Information we collect from you may be used to personalize your experience, improve our Website and customer service, send notification emails such as password reminders, and updates, and run and operate our Services. We will retain your personal information for as long as your account is active or as needed to provide you services, comply with our legal obligations, resolve disputes, and enforce our agreements.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">4. User Rights</h2>
                    <p>
                        You have the right to access, correct, delete, or transfer your personal data. You may exercise these rights by contacting us directly at support@waitmate.app. Under the GDPR, you also have the right to request the deletion of your personal data, commonly referred to as the "right to be forgotten."
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">5. International Data Transfers</h2>
                    <p>
                        Personal information collected may be stored and processed in any country where we have facilities or in which we engage service providers. By using our Services, you understand that your information may be transferred to countries outside of your country of residence, which may have different data protection rules than in your country.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">6. Information Security</h2>
                    <p>
                        We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. Despite these measures, no data transmission over the Internet or a wireless network can be guaranteed to be 100% secure.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">7. Legal Disclosure</h2>
                    <p>
                        We will disclose information we collect, use, or receive if required by law, such as to comply with a subpoena, or similar legal process when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">8. CCPA - Rights of California Residents</h2>
                    <p>
                        If you are a California resident, you have specific rights regarding the access to and deletion of your personal data. You may request disclosure of the categories and specific pieces of personal data we have collected about you by contacting us.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">9. Changes and Amendments</h2>
                    <p>
                        We reserve the right to modify this Privacy Policy at any time. We will notify you of significant changes by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">10. Acceptance of This Policy</h2>
                    <p>
                        By using the Website or its Services, you agree to this Policy. If you do not agree, you should not use our Website or Services.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">11. Contacting Us</h2>
                    <p>
                        If you have any questions about this Policy or your personal data, please contact us at support@waitmate.app. If you have complaints about our handling of your data, you may also contact the UK's Information Commissioner's Office.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
