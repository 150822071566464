import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import logo from '../assets/goqueuelogo.png';

const Navbar = () => {
  const { isAuthenticated, user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate("/login");
    setIsOpen(false); // Close the menu after logout
  };

  useEffect(() => {
    console.log("User in Navbar:", user);
    console.log("isAuthenticated:", isAuthenticated);
  }, [user, isAuthenticated]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = () => {
    setIsOpen(false); // Close the menu when a menu item is clicked
  };


  const isActive = (path) => {
    if (path === "/create-waiting-list") {
      return location.pathname === path || location.pathname === "/add-waiting-list"
        ? "bg-white bg-opacity-20 rounded-md px-2 py-1"
        : "";
    }
    return location.pathname === path ? "bg-white bg-opacity-20 rounded-md px-2 py-1" : "";
  };

  return (
    <nav className="w-full bg-[#0D1B2A] h-16 flex items-center px-5 gap-10 top-0 z-50">
      <div className="flex items-center w-full">
      <Link to="/" onClick={handleMenuItemClick}>
  <img src={logo} alt="WaitMate Logo" className="h-5 ml-2 object-contain" />
</Link>
        <ul className={`hidden lg:flex flex-row items-center space-x-4 ml-10`}>
          {isAuthenticated ? (
            <>
              <li className={isActive("/dashboard")}>
                <Link to="/dashboard" className="text-white px-4 py-2"onClick={handleMenuItemClick}>Home</Link>
              </li>
              <li className={isActive("/search-organizations")}>
                <Link to="/search-organizations" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Search Organizations</Link>
              </li>
              <li className={isActive("/manage-people")}>
                <Link to="/manage-people" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Manage People</Link>
              </li>
              <li className={isActive("/create-waiting-list")}>
                <Link to="/create-waiting-list" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Create Waiting List</Link>
              </li>
              {user && user.role === "owner" && (
                <li className={isActive("/manage-organization")}>
                  <Link to="/manage-organization" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Manage Organization</Link>
                </li>
              )}
              <li className={isActive("/account-settings")}>
                <Link to="/account-settings" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Account Settings</Link>
              </li>
              <li>
                <Link to="/login" onClick={handleLogout} className="text-white px-4 py-2">Logout</Link>
              </li>
            </>
          ) : (
            <>
              <li className={isActive("/login")}>
                <Link to="/login" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Home</Link>
              </li>
              <li className={isActive("/login")}>
                <Link to="/login" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Login</Link>
              </li>
              <li className={isActive("/register")}>
                <Link to="/register" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Register</Link>
              </li>
            </>
          )}
        </ul>
        <button
          className="lg:hidden text-white ml-auto text-2xl"
          onClick={toggleMenu}
        >
          ☰
        </button>
      </div>
      {isOpen && (
        <ul className="flex flex-col items-start space-y-3 absolute top-16 left-0 bg-[#0D1B2A] w-full p-5 z-50">
          {isAuthenticated ? (
            <>
              <li className={isActive("/dashboard")}>
                <Link to="/dashboard" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Home</Link>
              </li>
              <li className={isActive("/search-organizations")}>
                <Link to="/search-organizations" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Search Organizations</Link>
              </li>
              <li className={isActive("/manage-people")}>
                <Link to="/manage-people" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Manage People</Link>
              </li>
              <li className={isActive("/create-waiting-list")}>
                <Link to="/create-waiting-list" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Create Waiting List</Link>
              </li>
              {user && user.role === "owner" && (
                <li className={isActive("/manage-organization")}>
                  <Link to="/manage-organization" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Manage Organization</Link>
                </li>
              )}
              <li className={isActive("/account-settings")}>
                <Link to="/account-settings" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Account Settings</Link>
              </li>
              <li>
                <Link to="/login" onClick={handleLogout} className="text-white px-4 py-2">Logout</Link>
              </li>
            </>
          ) : (
            <>
              <li className={isActive("/login")}>
                <Link to="/login" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Home</Link>
              </li>
              <li className={isActive("/login")}>
                <Link to="/login" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Login</Link>
              </li>
              <li className={isActive("/register")}>
                <Link to="/register" className="text-white px-4 py-2" onClick={handleMenuItemClick}>Register</Link>
              </li>
            </>
          )}
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
