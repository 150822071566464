import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import backgroundImage from './Auth/goqueueback.png';

const VerifyEmailMessage = () => {
  const { user, resendVerificationEmail, checkVerificationStatus, sendPhoneVerificationCode, verifyPhoneNumber } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("User state on verification page:", user);
  }, [user]);
  

  const handleResendEmail = async () => {
    try {
      const msg = await resendVerificationEmail(user.email);
      setMessage(msg);
    } catch (error) {
      setMessage("Error resending verification email");
      console.error("Error resending verification email:", error);
    }
  };

  const handleCheckEmailVerification = async () => {
    try {
      const isVerified = await checkVerificationStatus(user.email);
      if (isVerified) {
        navigate(user.redirectTo);
      } else {
        setMessage("Your email is not verified yet. Please check your inbox.");
      }
    } catch (error) {
      setMessage("Error checking verification status");
      console.error("Error checking verification status:", error);
    }
  };


  if (!user) {
    return <div>Loading...</div>; // or handle the null case more appropriately
  }

  const handleResendPhoneCode = async () => {
    try {
       await sendPhoneVerificationCode({ phoneNumber: user.phoneNumber });
      setMessage("Verification code sent to your phone.");
    } catch (error) {
      setMessage("Error resending phone verification code");
      console.error("Error resending phone verification code:", error);
    }
  };

  const handleVerifyPhone = async () => {
    try {
      const response = await verifyPhoneNumber({ verificationCode: phoneCode });
      // Check if the status is 200 or if the message is correct
      if (response.message === "Phone number verified") {
        navigate(user.redirectTo);
      } else {
        setMessage("Incorrect verification code. Please try again.");
      }
    } catch (error) {
      setMessage("Error verifying phone number");
      console.error("Error verifying phone number:", error);
    }
  };
  

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {/* Background Image for Desktop/Tablet */}
      <div
        className="hidden md:block md:w-2/3 min-h-screen bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>

      {/* Background Color for Desktop/Tablet and Mobile view */}
      <div className="md:w-1/3 w-full flex items-center justify-center bg-[#F8FEFF] p-6">
        <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md md:max-w-none md:w-96">
          <h2 className="text-3xl font-semibold text-center mb-4">Complete Your Verification</h2>

          {/* Email Verification Section */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Email Verification</h3>
            <p className="text-center mb-2">
              {user.isVerified ? (
                <span className="text-green-500">✅ Email verified</span>
              ) : (
                <span className="text-red-500">❌ Email not verified</span>
              )}
            </p>
            {!user.isVerified && (
              <>
                <button
                  onClick={handleResendEmail}
                  className="w-full flex justify-center py-2 px-4 mb-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Resend Verification Email
                </button>
                <button
                  onClick={handleCheckEmailVerification}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  I'm verified. Check again
                </button>
              </>
            )}
          </div>

          {/* Phone Verification Section */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Phone Verification</h3>
            <p className="text-center mb-2">
              {user.phoneVerified ? (
                <span className="text-green-500">✅ Phone verified</span>
              ) : (
                <span className="text-red-500">❌ Phone not verified</span>
              )}
            </p>
            {!user.phoneVerified && (
              <>
                <input
                  type="text"
                  value={phoneCode}
                  onChange={(e) => setPhoneCode(e.target.value)}
                  placeholder="Enter verification code"
                  className="w-full mb-2 px-4 py-2 border border-gray-300 rounded-md"
                />
                <button
                  onClick={handleVerifyPhone}
                  className="w-full flex justify-center py-2 px-4 mb-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Verify Phone
                </button>
                <button
                  onClick={handleResendPhoneCode}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Resend Phone Verification Code
                </button>
              </>
            )}
          </div>

          {message && <p className="text-center mt-4 text-gray-600">{message}</p>}
          <p className="mt-6 text-center text-gray-600">
            Need help? <Link to="/contact" className="font-medium text-indigo-600 hover:text-indigo-500">Contact Support</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailMessage;
