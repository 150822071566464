import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import backgroundImage from './goqueueback.png';
import logo from '../../assets/goqueuelogo.png';


const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const redirectTo = location.state?.from || "/";
    try {
      await login(email, password, navigate, redirectTo);
    } catch (err) {
      setError(err.message);
    }
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="min-h-screen flex flex-row">
      {/* Background Image for Desktop/Tablet */}
      <div
        className="hidden lg:block lg:w-2/3 min-h-screen bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>

      {/* Background Color for Desktop/Tablet and Mobile view */}
      <div className="lg:w-1/3 w-full flex items-center justify-center bg-[#F8FEFF] p-6">
      
        <div className="bg-white rounded-lg shadow-md w-full max-w-md md:max-w-none md:w-96">
      
      <div className="w-full flex justify-center bg-[#0D1B2A] p-6 rounded-t-lg">
        <img src={logo} alt="WaitMate Logo" className="h-8 object-contain" />
      </div>
  <div className="p-6">        <h2 className="text-3xl font-semibold text-center mb-4">Sign In</h2>
          <p className="text-center mb-6">Sign in with your existing account.</p>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Email address</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email address"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <div className="relative mt-1">
                <input
                  type={passwordVisible ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                >
                  {passwordVisible ? "Hide" : "Show"}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between">
      
              <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </a>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign In
              </button>
            </div>
          </form>
          <p className="mt-6 text-center  text-gray-600">
            Don’t have an account? <a href="/register" className="font-medium text-indigo-600 hover:text-indigo-500">Sign up</a>
          </p></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
