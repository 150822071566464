import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import defaultBackground from '../assets/goqueueback.jpg';
import defaultLogo from '../assets/orgicon.jpg';

const OrganizationSearch = () => {
  const [organizations, setOrganizations] = useState([]);
  const [waitingLists, setWaitingLists] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showWaitingLists, setShowWaitingLists] = useState(false);
  const navigate = useNavigate();

  const fetchOrganizations = async (query = '') => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organization/search`, {
        params: { query },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setOrganizations(res.data.organizations);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  const fetchWaitingLists = async (query) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/waiting-list/search`, {
        params: { query },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setWaitingLists(res.data.waitingLists);
      setShowWaitingLists(true);
    } catch (error) {
      console.error('Error fetching waiting lists:', error);
    }
  };

  useEffect(() => {
    // Fetch all organizations initially
    fetchOrganizations();
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    await fetchOrganizations(searchQuery);
    await fetchWaitingLists(searchQuery);
  };

  const handleCardClick = (orgId) => {
    navigate(`/organization/${orgId}`);
  };

  return (
    <div className="bg-[#F8FEFF] min-h-screen p-6 flex flex-col items-center z-30">
      <form onSubmit={handleSearch} className="w-full max-w-4xl mb-8 flex flex-col md:flex-row items-center gap-4">
        <input
          type="text"
          placeholder="Search organizations or waiting lists..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="flex-grow p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Search
        </button>
      </form>

      <div className="w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {organizations.map((org) => (
          <div 
            key={org._id} 
            className="bg-white border border-gray-300 rounded-lg shadow-md flex flex-col cursor-pointer"
            onClick={() => handleCardClick(org._id)}
          >
            <div
              className="w-full h-32 bg-cover bg-center rounded-t-lg relative"
              style={{ backgroundImage: `url(${org.backgroundImage || defaultBackground})` }}
            >
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  src={org.logo || defaultLogo}
                  alt={`${org.name} logo`}
                  className="w-24 h-24 rounded-full bg-white border-2 border-white shadow-md object-contain"
                  style={{ top: '50%' }}
                />
              </div>
            </div>
            <div className="p-6 text-center flex flex-col flex-grow"> {/* Added padding here */}
              <h3 className="text-lg font-semibold">{org.name}</h3>
              {org.address && <p className="text-sm text-gray-600">Address: {org.address}</p>}
              {org.postcode && <p className="text-sm text-gray-600">Postcode: {org.postcode}</p>}
              {org.website && (
                <p className="text-sm text-gray-600">
                  Website: <a href={org.website} target="_blank" rel="noopener noreferrer" className="text-indigo-600">{org.website}</a>
                </p>
              )}
              {org.email && (
                <p className="text-sm text-gray-600">
                  Email: <a href={`mailto:${org.email}`} className="text-indigo-600">{org.email}</a>
                </p>
              )}
              {org.contactNumber && <p className="text-sm text-gray-600 flex-grow">Contact Number: {org.contactNumber}</p>}
            </div>
            <div className="p-6 pt-2 mt-auto">
              <Link to={`/organization/${org._id}`} className="block w-full text-center px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700">
                View Waiting Lists
              </Link>
            </div>
          </div>
        ))}
      </div>

      {showWaitingLists && (
        <div className="w-full max-w-7xl mt-10">
          <h2 className="text-2xl font-semibold mb-6">Waiting Lists</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {waitingLists.map((list) => (
              <div key={list._id} className="bg-white border border-gray-300 p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold">{list.name}</h3>
                <p className="text-gray-600">{list.description}</p>
                <p className="text-gray-600">Organization: {list.organization.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganizationSearch;
