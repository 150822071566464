import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreateOrganization = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    address: "",
    postcode: "",  // Add this line
    logo: "",
    backgroundImage: "",
    contactName: "",
    contactNumber: "",
    website: "",
    email: "",
    vanityUrl: "",
    subscriptionTier: "Free",
  });
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleChange = (e) => {
    if (e.target.name === "vanityUrl") {
      setFormData({ ...formData, [e.target.name]: e.target.value.toLowerCase() }); // Convert vanity URL to lowercase
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`${apiBaseUrl}/organization/create`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("Organization created:", res.data);

      window.scrollTo(0, 0); // Add this line to scroll to the top of the page
      navigate("/create-waiting-list");
    } catch (err) {
      console.error("Error creating organization:", err);
      setError(err.response?.data?.message || "Failed to create organization");
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="min-h-screen bg-[#F8FEFF] flex items-center justify-center">
      <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
        <h2 className="text-3xl font-semibold text-center mb-4">Create Organization</h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Organization Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Postcode</label>
            <input
              type="text"
              name="postcode"
              value={formData.postcode}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Logo URL</label>
            <input
              type="text"
              name="logo"
              value={formData.logo}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Background Image URL</label>
            <input
              type="text"
              name="backgroundImage"
              value={formData.backgroundImage}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Contact Name</label>
            <input
              type="text"
              name="contactName"
              value={formData.contactName}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Contact Number</label>
            <input
              type="text"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Website</label>
            <input
              type="text"
              name="website"
              value={formData.website}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Contact Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Vanity URL</label>
            <div className="flex items-center">
              <span className="text-gray-700 mr-2">https://waitmate.app/</span>
              <input
                type="text"
                name="vanityUrl"
                value={formData.vanityUrl}
                onChange={handleChange}
                className="mt-1 block flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Subscription Tier
              {/* <button type="button" onClick={toggleModal} className="text-blue-500 text-xs ml-2 underline">
                Show subscription details
              </button> */}
            </label>
            <select
              name="subscriptionTier"
              value={formData.subscriptionTier}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="Free">Free</option>
              {/* <option value="Basic">Basic</option>
              <option value="Club/Society/Charity">Club/Society/Charity</option>
              <option value="Pro">Pro</option>
              <option value="Premium">Premium</option>
              <option value="Enterprise">Enterprise</option> */}
            </select>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-green-500 text-green-500 rounded hover:bg-green-500 hover:text-white transition-colors"
            >
              Submit
            </button>
          </div>
        </form>

        {/* Modal */}
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-4xl relative">
              <button
                type="button"
                onClick={toggleModal}
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 font-semibold"
              >
                Close
              </button>
              <h3 className="text-xl font-semibold text-center mb-4">Subscription Comparison</h3>
              <table className="w-full border-collapse">
                <thead className="bg-gray-800 text-white font-semibold">
                  <tr>
                    <th className="border p-2">Tier</th>
                    <th className="border p-2">Waiting Lists</th>
                    <th className="border p-2">People on Lists</th>
                    <th className="border p-2">Email Notifications</th>
                    <th className="border p-2">SMS Notifications</th>
                    <th className="border p-2">Priority Support</th>
                    <th className="border p-2">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-gray-100 text-gray-800 font-semibold">
                    <td className="border p-2">Free</td>
                    <td className="border p-2">1</td>
                    <td className="border p-2">10</td>
                    <td className="border p-2">Yes</td>
                    <td className="border p-2">No</td>
                    <td className="border p-2">No</td>
                    <td className="border p-2">£0</td>
                  </tr>
                  <tr className="bg-white">
                    <td className="border p-2">Basic</td>
                    <td className="border p-2">5</td>
                    <td className="border p-2">Unlimited</td>
                    <td className="border p-2">Yes</td>
                    <td className="border p-2">No</td>
                    <td className="border p-2">No</td>
                    <td className="border p-2">£7</td>
                  </tr>
                  <tr className="bg-gray-100 text-gray-800 font-semibold">
                    <td className="border p-2">Club/Society/Charity</td>
                    <td className="border p-2">10</td>
                    <td className="border p-2">Unlimited</td>
                    <td className="border p-2">Yes</td>
                    <td className="border p-2">No</td>
                    <td className="border p-2">No</td>
                    <td className="border p-2">£10</td>
                  </tr>
                  <tr className="bg-white">
                    <td className="border p-2">Pro</td>
                    <td className="border p-2">10</td>
                    <td className="border p-2">Unlimited</td>
                    <td className="border p-2">Yes</td>
                    <td className="border p-2">Up to 100 SMS</td>
                    <td className="border p-2">No</td>
                    <td className="border p-2">£20</td>
                  </tr>
                  <tr className="bg-gray-100 text-gray-800 font-semibold">
                    <td className="border p-2">Premium</td>
                    <td className="border p-2">Unlimited</td>
                    <td className="border p-2">Unlimited</td>
                    <td className="border p-2">Yes</td>
                    <td className="border p-2">Up to 500 SMS</td>
                    <td className="border p-2">Yes</td>
                    <td className="border p-2">£35</td>
                  </tr>
                  <tr className="bg-white">
                    <td className="border p-2">Enterprise</td>
                    <td className="border p-2">Custom</td>
                    <td className="border p-2">Unlimited</td>
                    <td className="border p-2">Yes</td>
                    <td className="border p-2">Up to 1000 SMS</td>
                    <td className="border p-2">Yes</td>
                    <td className="border p-2">Custom Pricing</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateOrganization;
