import React from "react";

const Terms = () => {
    return (
        <div className="bg-[#F8FEFF] min-h-screen flex justify-center px-4">
            <div className="w-full max-w-[800px] mt-10 bg-white rounded-lg shadow-md p-6">
                <h1 className="text-3xl font-semibold mb-4">Terms of Use</h1>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">1. Acceptance of Terms</h2>
                    <p>
                        By accessing and using this website (the "Site"), you accept and agree to be bound by the terms and provision of this agreement. In addition, when using this website's particular services, you shall be subject to any posted guidelines or rules applicable to such services. All such guidelines or rules are hereby incorporated by reference into the Terms of Use.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">2. Service Description</h2>
                    <p>
                        This Site provides an online waiting list management service ("Service") that allows organizations such as restaurants, barbershops, schools, clubs, and societies to maintain first-come, first-served waiting lists. The Service may be used by businesses to manage queue entries, notifications, and customer interactions related to their waiting lists.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">3. Privacy Policy</h2>
                    <p>
                        Your privacy is very important to us. Our Privacy Policy, which is incorporated by reference into these Terms of Use, is available on a separate page and explains how we collect, use, and disclose information that pertains to your privacy.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">4. User Conduct</h2>
                    <p>
                        You are responsible for all data that you transmit or process via the Site. You agree to use the Service for lawful purposes only and in compliance with all applicable local, state, national, and international laws and regulations.
                    </p>
                    <ul className="list-disc ml-6">
                        <li>You must not use the Site to mislead others, conduct fraudulent activities, or perpetrate scams.</li>
                        <li>You must not transmit any malicious or technologically harmful material.</li>
                        <li>You must not impersonate any person or entity or misrepresent your affiliation with a person or entity.</li>
                    </ul>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">5. Communication</h2>
                    <p>
                        The Service may contact users via email or SMS as part of its functionality. Users agree to be contacted in this manner when they provide their contact information. However, access to other users' contact details is not provided directly; all communication must be facilitated through the Service to ensure privacy and compliance with data protection laws. Misuse of the communication tools for spam or any form of harassment is strictly prohibited.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">6. Custom Questions</h2>
                    <p>
                        Owners of waiting lists may define custom questions to better manage their lists. These questions must:
                    </p>
                    <ul className="list-disc ml-6">
                        <li>Be strictly necessary for the service they provide.</li>
                        <li>Comply with all relevant data protection laws, including the General Data Protection Regulation (GDPR).</li>
                        <li>Not collect sensitive information or be used to discriminate based on protected characteristics.</li>
                    </ul>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">7. Account Integrity and Fair Use</h2>
                    <p>
                        The Site offers various service tiers, including a free tier. Organizations are prohibited from creating multiple accounts to circumvent the limits of the free tier or any other service restrictions. Each organization is allowed to register for one free account only. Engaging in such practices may result in the suspension or termination of your account and access to the Service.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">8. Intellectual Property</h2>
                    <p>
                        All content included on the Site, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of the Site owner or its licensors and protected by copyright and other intellectual property laws.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">9. Disclaimer of Warranties</h2>
                    <p>
                        The Site and its components are offered for informational purposes only; the Site shall not be responsible or liable for the accuracy, usefulness or availability of any information transmitted or made available via the Site, and shall not be responsible or liable for any error or omissions in that information.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">10. Limitation of Liability</h2>
                    <p>
                        In no event shall the Site, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">11. Modifications to Terms of Use</h2>
                    <p>
                        We reserve the right, at our sole discretion, to modify or replace these Terms of Use at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">12. Contact Us</h2>
                    <p>
                        If you have any questions about these Terms of Use, please contact us at support@waitmate.app.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Terms;
