import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const ConfigureWaitingList = ({ isEditMode = false, organizationId: propOrganizationId }) => {
  const { waitingListId } = useParams();
  const [organizationId, setOrganizationId] = useState(propOrganizationId || "");
  const [waitingListDetails, setWaitingListDetails] = useState({
    name: "",
    description: "",
    type: "dynamic",
    bannerImageUrl: "",
    iconUrl: "",
    allowViewPosition: false,
    allowViewQueueLength: false,
    visibility: true,
    managers: [], // List of manager IDs
  });
  const [coreFields, setCoreFields] = useState({
    dateOfBirth: false,
    address: false,
    gender: false,
  });
  const [organizationCustomQuestions, setOrganizationCustomQuestions] = useState([]);
  const [selectedCustomQuestions, setSelectedCustomQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({
    questionText: "",
    dataType: "text",
    required: false,
    choices: [],
  });
  const [newChoice, setNewChoice] = useState("");
  const [error, setError] = useState("");
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [availableManagers, setAvailableManagers] = useState([]); // List of available managers
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchWaitingListConfiguration = async () => {
      try {
        console.log("Fetching waiting list configuration...");
        const res = await axios.get(`${apiBaseUrl}/waiting-list/${waitingListId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log("Fetched waiting list:", res.data.waitingList);
        
        // Extract only the manager IDs
        const managers = res.data.waitingList.managers.map(manager => 
          typeof manager === 'object' ? manager._id : manager
        ).filter(id => id !== null);

        setWaitingListDetails({ ...res.data.waitingList, managers });
        setCoreFields(res.data.waitingList.coreFields);
        setSelectedCustomQuestions(res.data.waitingList.questions);
        setOrganizationId(res.data.waitingList.organization._id);
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch waiting list configuration");
        console.error("Error fetching waiting list configuration:", err);
      }
    };

    if (isEditMode && waitingListId) {
      fetchWaitingListConfiguration();
    }
  }, [isEditMode, waitingListId, apiBaseUrl]);

  useEffect(() => {
    if (organizationId) {
      const fetchOrganizationCustomQuestions = async () => {
        try {
          console.log("Fetching custom questions for organization ID:", organizationId);
          const res = await axios.get(`${apiBaseUrl}/organization/${organizationId}/custom-questions`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          setOrganizationCustomQuestions(res.data.customQuestions);
          console.log("Fetched custom questions:", res.data.customQuestions);
        } catch (err) {
          setError(err.response?.data?.message || "Failed to fetch custom questions");
          console.error("Error fetching custom questions:", err);
        }
      };

      const fetchAvailableManagers = async () => {
        try {
          console.log("Fetching available managers for organization ID:", organizationId);
          const res = await axios.get(`${apiBaseUrl}/organization/${organizationId}/managers`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          const currentUserId = localStorage.getItem("userId");
          const managers = res.data.managers;
          console.log("Fetched available managers:", managers);
          setAvailableManagers(managers);

          if (!waitingListDetails.managers.includes(currentUserId)) {
            setWaitingListDetails((prevDetails) => ({
              ...prevDetails,
              managers: [...prevDetails.managers.filter(id => id !== null && id !== currentUserId), currentUserId]
            }));
          }
        } catch (err) {
          setError(err.response?.data?.message || "Failed to fetch managers");
          console.error("Error fetching managers:", err);
        }
      };

      fetchOrganizationCustomQuestions();
      fetchAvailableManagers();
    }
  }, [organizationId, apiBaseUrl, waitingListDetails.managers]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0); // Add this line to scroll to the top of the page

    const validManagers = waitingListDetails.managers.filter(id => id !== null);
    console.log("Submitting form with managers:", validManagers);
    console.log("Core fields:", coreFields);
    console.log("Selected custom questions:", selectedCustomQuestions);
    if (validManagers.length === 0) {
      setError("At least one manager must be selected");
      return;
    }
    try {
      const url = isEditMode
        ? `${apiBaseUrl}/waiting-list/${waitingListId}`
        : `${apiBaseUrl}/waiting-list/create`;
      const method = isEditMode ? "put" : "post";

      await axios[method](
        url,
        {
          ...waitingListDetails,
          managers: validManagers,
          coreFields,
          questions: selectedCustomQuestions,
          organizationId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Form submitted successfully.");
      navigate(isEditMode ? `/waiting-list/${waitingListId}` : `/`);
    } catch (error) {
      setError(error.response?.data?.message || "Failed to configure waiting list");
      console.error("Error submitting form:", error);
    }
  };

  const handleAddNewQuestion = async () => {
    if (!newQuestion.questionText) {
      setError("Question text is required");
      return;
    }

    try {
      console.log("Adding new question:", newQuestion);
      const res = await axios.post(
        `${apiBaseUrl}/organization/${organizationId}/custom-questions`,
        newQuestion,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const updatedQuestions = res.data.customQuestions;
      setOrganizationCustomQuestions(updatedQuestions);
      console.log("New question added successfully. Updated questions:", updatedQuestions);
      const addedQuestion = updatedQuestions.find(
        (question) => question.questionText === newQuestion.questionText
      );
      if (addedQuestion) {
        setSelectedCustomQuestions([...selectedCustomQuestions, addedQuestion._id]);
      }
      setNewQuestion({
        questionText: "",
        dataType: "text",
        required: false,
        choices: [],
      });
      setNewChoice("");
      setShowAddQuestion(false);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to add new question");
      console.error("Error adding new question:", err);
    }
  };

  const handleAddChoice = () => {
    if (newChoice) {
      console.log("Adding choice to new question:", newChoice);
      setNewQuestion((prevQuestion) => ({
        ...prevQuestion,
        choices: [...prevQuestion.choices, newChoice],
      }));
      setNewChoice("");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setWaitingListDetails({
      ...waitingListDetails,
      [name]: type === "checkbox" ? checked : value,
    });
    console.log(`Updated ${name} to`, type === "checkbox" ? checked : value);
  };

  const handleManagerChange = (e) => {
    const { value, checked } = e.target;
    setWaitingListDetails((prevDetails) => {
      const filteredManagers = prevDetails.managers.filter(managerId => managerId !== null);
      const updatedManagers = checked
        ? [...filteredManagers, value]
        : filteredManagers.filter(managerId => managerId !== value);
      console.log("Updated managers:", updatedManagers);
      return { ...prevDetails, managers: updatedManagers };
    });
  };

  const handleCustomQuestionChange = (questionId) => {
    setSelectedCustomQuestions((prevSelected) => {
      const newState = prevSelected.includes(questionId)
        ? prevSelected.filter(id => id !== questionId)
        : [...prevSelected, questionId];
      console.log("Updated custom questions:", newState);
      return newState;
    });
  };

  return (
    <div className="min-h-screen bg-[#F8FEFF] flex justify-center px-4">
      <div className="w-full max-w-[1500px] mt-10 flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-1/2 flex flex-col space-y-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-3xl font-semibold text-center mb-4">
              {isEditMode ? "Edit Waiting List" : "Create Waiting List"}
            </h2>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  name="name"
                  value={waitingListDetails.name}
                  onChange={handleInputChange}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <input
                  type="text"
                  name="description"
                  value={waitingListDetails.description}
                  onChange={handleInputChange}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {/* <div>
                <label className="block text-sm font-medium text-gray-700">Type</label>
                <select
                  name="type"
                  value={waitingListDetails.type}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="dynamic">Dynamic</option>
                  <option value="static">Static</option>
                  <option value="temporary">Temporary</option>
                </select>
              </div> */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Banner Image URL</label>
                <input
                  type="text"
                  name="bannerImageUrl"
                  value={waitingListDetails.bannerImageUrl}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Icon URL</label>
                <input
                  type="text"
                  name="iconUrl"
                  value={waitingListDetails.iconUrl}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="space-y-2">
                <div>
                  <input
                    type="checkbox"
                    name="allowViewPosition"
                    checked={waitingListDetails.allowViewPosition}
                    onChange={handleInputChange}
                  />
                  <label className="ml-2">Allow View Position</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="allowViewQueueLength"
                    checked={waitingListDetails.allowViewQueueLength}
                    onChange={handleInputChange}
                  />
                  <label className="ml-2">Allow View Queue Length</label>
                </div>
                {/* <div>
                  <input
                    type="checkbox"
                    name="visibility"
                    checked={waitingListDetails.visibility}
                    onChange={handleInputChange}
                  />
                  <label className="ml-2">Visibility</label>
                </div> */}
              </div>
            </form>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4">List Managers</h3>
            <div className="space-y-2">
              {availableManagers.map((manager) => (
                <div key={manager._id} className="flex items-center">
                  <input
                    type="checkbox"
                    id={manager._id}
                    name="managers"
                    value={manager._id}
                    checked={waitingListDetails.managers.includes(manager._id)}
                    onChange={handleManagerChange}
                    className="mr-2"
                  />
                  <label htmlFor={manager._id} className="text-sm font-medium text-gray-700">{manager.name}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 space-y-8">
         
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4">Core Fields</h3>
            <div className="space-y-2">
              <div>
                <input
                  type="checkbox"
                  checked={coreFields.dateOfBirth}
                  onChange={(e) => setCoreFields({ ...coreFields, dateOfBirth: e.target.checked })}
                />
                <label className="ml-2">Date of Birth</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={coreFields.address}
                  onChange={(e) => setCoreFields({ ...coreFields, address: e.target.checked })}
                />
                <label className="ml-2">Address</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={coreFields.gender}
                  onChange={(e) => setCoreFields({ ...coreFields, gender: e.target.checked })}
                />
                <label className="ml-2">Gender</label>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4">Custom Questions</h3>
            <div className="space-y-2">
              {organizationCustomQuestions.length === 0 ? (
                <p>No custom questions</p>
              ) : (
                organizationCustomQuestions.map((question, index) => (
                  <div key={question._id}>
                    <input
                      type="checkbox"
                      checked={selectedCustomQuestions.includes(question._id)}
                      onChange={() => handleCustomQuestionChange(question._id)}
                    />
                    <label className="ml-2">{question.questionText}</label>
                  </div>
                ))
              )}
            </div>
            <button
              type="button"
              onClick={() => setShowAddQuestion(true)}
              className="px-4 py-2 border border-green-500 text-green-500 rounded hover:bg-green-500 hover:text-white transition-colors mt-4"
            >
              Add New Question
            </button>
          </div>
          <div className="flex justify-center space-x-4">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors mb-6"
            >
              Back
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className="px-4 py-2 border border-green-500 text-green-500 rounded hover:bg-green-500 hover:text-white transition-colors mb-6"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      {showAddQuestion && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4">Add New Question</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Question Text</label>
                <input
                  type="text"
                  value={newQuestion.questionText}
                  onChange={(e) => setNewQuestion({ ...newQuestion, questionText: e.target.value })}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Data Type</label>
                <select
                  value={newQuestion.dataType}
                  onChange={(e) => setNewQuestion({ ...newQuestion, dataType: e.target.value })}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="text">Text</option>
                  <option value="number">Number</option>
                  <option value="boolean">Yes/No</option>
                  <option value="date">Date</option>
                  <option value="choice">Choice</option>
                </select>
              </div>
              {newQuestion.dataType === "choice" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">Choices</label>
                  <div className="flex space-x-2">
                    <input
                      type="text"
                      value={newChoice}
                      onChange={(e) => setNewChoice(e.target.value)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <button
                      type="button"
                      onClick={handleAddChoice}
                      className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                    >
                      Add
                    </button>
                  </div>
                  <ul className="list-disc list-inside mt-2">
                    {newQuestion.choices.map((choice, index) => (
                      <li key={index}>{choice}</li>
                    ))}
                  </ul>
                </div>
              )}
              {newQuestion.dataType !== "boolean" && (<div>
                <input
                  type="checkbox"
                  checked={newQuestion.required}
                  onChange={(e) => setNewQuestion({ ...newQuestion, required: e.target.checked })}
                />
                <label className="ml-2">Required</label>
              </div>)}
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => setShowAddQuestion(false)}
                  className="px-4 py-2 border border-gray-500 text-gray-500 rounded hover:bg-gray-500 hover:text-white transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleAddNewQuestion}
                  className="px-4 py-2 border border-green-500 text-green-500 rounded hover:bg-green-500 hover:text-white transition-colors"
                >
                  Add Question
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigureWaitingList;
