import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem("token");
      console.log("Token from localStorage:", token);
      if (token) {
        try {
          const res = await axios.get(`${apiBaseUrl}/auth/check`, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          });
          console.log("Full response from /auth/check:", res.data);
          if (res.data.isAuthenticated) {
            setIsAuthenticated(true);
            setUser(res.data.user);
            setIsVerified(res.data.user.isVerified);
          } else {
            setIsAuthenticated(false);
            setUser(null);
            setIsVerified(false);
          }
        } catch (error) {
          console.error("Error checking auth status:", error);
          setIsAuthenticated(false);
          setUser(null);
          setIsVerified(false);
        }
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    console.log("AuthContext: User state updated", user);
  }, [user]);

  const login = async (email, password, navigate, redirectTo) => {
    try {
      const res = await axios.post(`${apiBaseUrl}/auth/login`, { email, password }, {
        withCredentials: true,
      });
      console.log("Response from /auth/login:", res.data);
      localStorage.setItem("token", res.data.token);
      setIsAuthenticated(true);
      setUser(res.data.user);
      setIsVerified(res.data.user.isVerified);
      navigate(redirectTo || "/");
    } catch (error) {
      console.error("Login failed:", error.response?.data?.message || error.message);
      throw new Error(error.response?.data?.message || "Login failed");
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setUser(null);
    setIsVerified(false);
  };

  const register = async (name, email, password, phoneNumber,redirectTo, navigate) => {
    try {
      const res = await axios.post(`${apiBaseUrl}/auth/register`, { name, email, password, phoneNumber, redirectTo }, {
        withCredentials: true,
      });
  
      console.log("Response from /auth/register:", res.data);
  
      if (res.data.token) {
        localStorage.setItem("token", res.data.token);
        setIsAuthenticated(true);
  
        const userRes = await axios.get(`${apiBaseUrl}/auth/check`, {
          headers: { Authorization: `Bearer ${res.data.token}` },
          withCredentials: true,
        });
  
        if (userRes.data.user) {
          setUser(userRes.data.user);
          setIsVerified(userRes.data.user.isVerified || false);
          // Ensure user is set before navigating
          navigate(redirectTo || "/verify-email-message");
        } else {
          throw new Error("User data not received from registration response");
        }
      } else {
        throw new Error("Token not received from registration response");
      }
    } catch (error) {
      console.error("Registration failed:", error.response?.data?.message || error.message);
      throw new Error(error.response?.data?.message || "Registration failed");
    }
  };
  
  

  const resendVerificationEmail = async (email) => {
    try {
      const res = await axios.post(`${apiBaseUrl}/auth/resend-verification-email`, { email }, {
        withCredentials: true,
      });
      console.log("Response from /auth/resend-verification-email:", res.data);
      return res.data.message;
    } catch (error) {
      console.error("Failed to resend verification email:", error.response?.data?.message || error.message);
      throw new Error(error.response?.data?.message || "Failed to resend verification email");
    }
  };

  const sendPhoneVerificationCode = async ({ phoneNumber }) => {
    try {
      const res = await axios.post(`${apiBaseUrl}/auth/send-phone-verification-code`, { phoneNumber }, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        withCredentials: true,
      });
      return res.data.message;
    } catch (error) {
      console.error("Failed to send phone verification code:", error.response?.data?.message || error.message);
      throw new Error(error.response?.data?.message || "Failed to send phone verification code");
    }
  };

  const verifyPhoneNumber = async ({ verificationCode }) => {
    try {
      const res = await axios.post(`${apiBaseUrl}/auth/verify-phone`, { verificationCode }, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        withCredentials: true,
      });
      if (res.data.message === "Phone number verified") {
        // Re-fetch user state to ensure all is up-to-date
        const userRes = await axios.get(`${apiBaseUrl}/auth/check`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          withCredentials: true,
        });
        setUser(userRes.data.user);
        setIsVerified(userRes.data.user.isVerified);
      }
      return res.data;
    } catch (error) {
      console.error("Failed to verify phone number:", error.response?.data?.message || error.message);
      throw new Error(error.response?.data?.message || "Failed to verify phone number");
    }
  };

  const checkVerificationStatus = async (email) => {
    try {
      const response = await axios.post(`${apiBaseUrl}/auth/check-verification-status`, { email });
      const { isVerified } = response.data;
      if (isVerified) {
        const userRes = await axios.get(`${apiBaseUrl}/auth/check`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          withCredentials: true,
        });
        setUser(userRes.data.user);
        setIsVerified(userRes.data.user.isVerified);
      }
      return isVerified;
    } catch (error) {
      console.error("Error checking verification status:", error);
      throw new Error("Error checking verification status");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setUser,  // <-- Ensure setUser is provided here
        isVerified,
        login,
        logout,
        register,
        resendVerificationEmail,
        checkVerificationStatus,
        sendPhoneVerificationCode,
        verifyPhoneNumber,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
